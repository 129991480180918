@font-face {
    font-family: 'Mecellem';
    src: url('Mecellem030822-Bold.woff2') format('woff2'),
        url('Mecellem030822-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mecellem';
    src: url('Mecellem310822-SemiBold.woff2') format('woff2'),
        url('Mecellem310822-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mecellem';
    src: url('Mecellem030822-Regular.woff2') format('woff2'),
        url('Mecellem030822-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
/* 
@font-face {
    font-family: 'mecellem_bold';
    src: url('https://www.mecellem.com/assets/fonts/Mecellem030822-Bold.woff2') format('woff2'), url('https://www.mecellem.com/assets/fonts/Mecellem030822-Bold.woff') format('woff');
    }
    
    @font-face {
    font-family: 'mecellem_regular';
    src: url('https://www.mecellem.com/assets/fonts/Mecellem030822-Regular.woff2') format('woff2'), url('https://www.mecellem.com/assets/fonts/Mecellem030822-Regular.woff') format('woff');
    }
    
    @font-face {
    font-family: 'mecellem_semibold';
    src: url('https://www.mecellem.com/assets/fonts/Mecellem310822-SemiBold.woff') format('woff2'), url('https://www.mecellem.com/assets/fonts/Mecellem310822-SemiBold.woff2') format('woff');
} */