$green      :  #00A696;
$red        :  #f54834;
$blue       :  #7245AB;
$skyblue    :  #0082FA;
$purple     :  #7245ab;
$yellow     :  #FFC938;

$themegreeen : #034348;
$themeblue  :  #3efae1;

$white      :  #ffffff;
$black      :  #000000;
$gray       :  #c5cace;
$textgray   :  #788086;
$lightgray  :  #f1f7f7;

$p1         : 10px;
$p2         : 20px;
$p3         : 30px;

$br4        : 4px;

$m-auto     : 0 auto;