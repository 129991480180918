body {
	background: $lightgray
}

.dendrogramcart {
	.content {
		background: $white;
		margin: 20px;
		border-radius: 6px;
		box-shadow: 0 0 10px 0 rgba(0, 0, 0, .07);
		position: relative;

		&__area {
			width: calc(100% - 310px);
			border-right: 2px solid #C1D5D5 !important
		}

		.chart_content {
			padding: 30px 0;
			text-align: center;
			position: relative;
		}
	}

	.disabled {
		opacity: 0.25;
		pointer-events: none;
		/* Prevent interaction with disabled elements */
		/* Add any other styles to visually represent disabled state */
	}
}

/*.visible, .visibleNode, .visibleText, .visible1, .visible2, .visible3, .visible4, .visible5, .visible6, , .visible7, .visibleNT1, .visibleNT2, .visibleNT3, .visibleNT4, .visibleNT5, .visibleNT6, .visibleNT7, .flagVisible{opacity: 1;}

.visibleOut, .hidden {opacity: 0;}*/
.hidden {display: none; }
circle, text, path{ transition:.1s ease all; -moz-transition:.1s ease all; }

.visible, .visible1 {animation: fadeIn 1s ease-in; -moz-animation: fadeIn 1s ease-in; } 
.visibleNode, .visibleText, .visible2 {animation: fadeIn 1.8s ease-in; -moz-animation: fadeIn 1.8s ease-in; } 
.visible3 {animation: fadeIn 1.6s ease-in; -moz-animation: fadeIn 1.6s ease-in; } 
.visible4 {animation: fadeIn 2s ease-in; -moz-animation: fadeIn 2s ease-in; } 
.visible5 {animation: fadeIn 2.3s ease-in; -moz-animation: fadeIn 2.3s ease-in; } 
.visible6 {animation: fadeIn 2.6s ease-in; -moz-animation: fadeIn 2.6s ease-in; } 
.visibleNT1 {animation: fadeIn 1.5s ease-in; -moz-animation: fadeIn 1.5s ease-in; } 
.visibleNT2 {animation: fadeIn 1.8s ease-in; -moz-animation: fadeIn 1.8s ease-in; } 
.visibleNT3 {animation: fadeIn 2.1s ease-in; -moz-animation: fadeIn 2.1s ease-in; } 
.visibleNT4 {animation: fadeIn 2.4s ease-in; -moz-animation: fadeIn 2.4s ease-in; } 
.visibleNT5 {animation: fadeIn 2.7s ease-in; -moz-animation: fadeIn 2.7s ease-in; }

.visibleOut {animation: fadeOut 2s ease-out; -moz-animation: fadeOut 2s ease-out; }  
.visible7, .visibleNT6 {animation: fadeIn 3s ease-in; -moz-animation: fadeIn 3s ease-in; } 
.visibleNT7 {animation: fadeIn 3.2s ease-in; -moz-animation: fadeIn 3.2s ease-in; } 

@keyframes fadeIn {
	from {opacity: 0; } 
	to {opacity: 1; }
}

@keyframes fadeOut {
	from {opacity: 1; } 
	to {opacity: 0; }
  }

.p-relative {position: relative; }
.filter-btn {
	background-color: #f6f4ff;
    border: none;
    padding: 10px 24px;
    border-radius: 6px;
    font-size: 16px;
	color: #ffffff;
}
.radio_btn {
	.form-check-input[type=radio] {
		background-color: #e9f1f8;
		border-color: #e9f1f8;
	}
	.form-check-input:checked {
		/* background-color: #525962; */
		border: 4px solid #e9f1f8;
	}
	.form-check-input:checked[type=radio] {
		background-image: none !important;
		background: #8675fa;
	}
	.form-check-input:focus {
		box-shadow: none;
	}
	.form-check-input[type=radio] + label {
	font-weight: 500;
	}
	.form-check-input:checked[type=radio] + label {
	font-weight: bold;
	}
}	


.content_graph {
	padding-left: 304px;
	.total-cr {
		position: absolute;
		top:52.3%;
		left: 29px;
		.line-vr {
			position: relative;
			&::before {
				content: "";
				background: rgb(129,137,250);
				background: linear-gradient(90deg, rgba(129,137,250,0.07335434173669464) 0%, rgba(129,137,250,1) 100%);
				height: 2px;
				width: 154px;
				position: absolute;
				right: -174px;
				top: 35px;
			}
		}
	}
}

.total-cr_2 {
	position: absolute;
	top:51.4%;
	left: 29px;
	.line-vr {
		position: relative;
		&::before {
			content: "";
			background: rgb(129,137,250);
			background: linear-gradient(90deg, rgba(129,137,250,0.07335434173669464) 0%, rgba(129,137,250,1) 100%);
			height: 2px;
			width: 154px;
			position: absolute;
			right: -174px;
			top: 35px;
		}
	}
}
.popupNode{ position: absolute; padding: 10px 0 10px 10px; background-color: #fff; border-radius: 2px; box-shadow:0 10px 20px 2px rgba(0, 0, 0, 0.1); width: 75%; left: 0; top: 0; margin: 15px;
    ul{ font-weight: 400; font-size: 12px; text-align: left; list-style: none; position: relative; margin: 0; padding-left: 0;
        &.main{
            &:before {content: ''; position: absolute; width: 1px; left: 10px; top: 18px; bottom: 10px; border-left: 1px dashed #ececed; }
            > li{
                &:before {content: ''; position: absolute; left: 8px; top: 14px; width: 5px; height: 
                5px; background: rgb(63, 83, 115) !important; border-radius: 100%; }
                div {margin-bottom: 8px; }
            }
            li { padding: 8px 0px 0px 10px; position: relative;
                &:last-child {border-bottom: none; list-style: none; position: relative;}
                .cnt {padding-left: 10px; margin-bottom: 0 !important; border-bottom: 1px solid #ececed !important; padding-bottom: 10px;}
                .sub_list{padding-left: 0; margin-top: 0px; position: relative;
                    &:before {content: ''; position: absolute; width: 1px; left: 10px; top: 18px; bottom: 10px; border-left: 1px dashed #ececed; }
                    &:after {content: ''; position: absolute; width: 1px; left: 0px; top: 13px; bottom: 0px; border-left: 1px solid #fff; }
                    li{
                        &:before {content: ''; position: absolute; left: 8px; top: 13px; width: 5px; height: 5px; border-radius: 100%; }
                        &:first-child{
                            &:after {content: ''; position: absolute; left: 0px; top: 10px; width: 7px; height: 5px; border-bottom: 1px dashed #ececed; background: transparent !important; border-left: 1px dashed #ececed; border-radius: 0 0 0 5px; }
                        }
                    }
                }
            }
        }
        .blue{            
            &:before {background: #5500D5 !important; }
        }
        .light-blue{
            &:before{ background: #CFAEFD;}
        }
        .text_blue {color: #5500D5; }
        li{
	        &.last-node{
	            .cnt{ border-bottom:none !important }
	        }
        }
    }
}