.top_breadcrumb{ background: $white; padding:20px; margin-top:20px;
	.breadcrumb{ margin-bottom:0;
		.breadcrumb-item{
			&:before{content: ''; background: url('../images/next.png') no-repeat center; width: 40px;
    height: 20px; margin-top: 2px;}
			&:first-child{
				&:before{content: ' '; display: none}
			}
			img{ width:16px; height:auto; vertical-align: middle;}
			a{ color:$textgray }
			&.active{
				font-weight: bold; color: $black;
			}
		}
	}
}