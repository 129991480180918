.top_breadcrumb {
  background: #ffffff;
  padding: 20px;
  margin-top: 20px; }
  .top_breadcrumb .breadcrumb {
    margin-bottom: 0; }
    .top_breadcrumb .breadcrumb .breadcrumb-item:before {
      content: '';
      background: url("../images/next.png") no-repeat center;
      width: 40px;
      height: 20px;
      margin-top: 2px; }
    .top_breadcrumb .breadcrumb .breadcrumb-item:first-child:before {
      content: ' ';
      display: none; }
    .top_breadcrumb .breadcrumb .breadcrumb-item img {
      width: 16px;
      height: auto;
      vertical-align: middle; }
    .top_breadcrumb .breadcrumb .breadcrumb-item a {
      color: #788086; }
    .top_breadcrumb .breadcrumb .breadcrumb-item.active {
      font-weight: bold;
      color: #000000; }

body {
  background: #f1f7f7; }

.dendrogramcart .content {
  background: #ffffff;
  margin: 20px;
  border-radius: 6px;
  -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.07);
          box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.07);
  position: relative; }
  .dendrogramcart .content__area {
    width: calc(100% - 310px);
    border-right: 2px solid #C1D5D5 !important; }
  .dendrogramcart .content .chart_content {
    padding: 30px 0;
    text-align: center;
    position: relative; }
.dendrogramcart .disabled {
  opacity: 0.25;
  pointer-events: none;
  /* Prevent interaction with disabled elements */
  /* Add any other styles to visually represent disabled state */ }

/*.visible, .visibleNode, .visibleText, .visible1, .visible2, .visible3, .visible4, .visible5, .visible6, , .visible7, .visibleNT1, .visibleNT2, .visibleNT3, .visibleNT4, .visibleNT5, .visibleNT6, .visibleNT7, .flagVisible{opacity: 1;}

.visibleOut, .hidden {opacity: 0;}*/
.hidden {
  display: none; }

circle, text, path {
  -webkit-transition: .1s ease all;
  transition: .1s ease all;
  -moz-transition: .1s ease all; }

.visible, .visible1 {
  -webkit-animation: fadeIn 1s ease-in;
          animation: fadeIn 1s ease-in;
  -moz-animation: fadeIn 1s ease-in; }

.visibleNode, .visibleText, .visible2 {
  -webkit-animation: fadeIn 1.8s ease-in;
          animation: fadeIn 1.8s ease-in;
  -moz-animation: fadeIn 1.8s ease-in; }

.visible3 {
  -webkit-animation: fadeIn 1.6s ease-in;
          animation: fadeIn 1.6s ease-in;
  -moz-animation: fadeIn 1.6s ease-in; }

.visible4 {
  -webkit-animation: fadeIn 2s ease-in;
          animation: fadeIn 2s ease-in;
  -moz-animation: fadeIn 2s ease-in; }

.visible5 {
  -webkit-animation: fadeIn 2.3s ease-in;
          animation: fadeIn 2.3s ease-in;
  -moz-animation: fadeIn 2.3s ease-in; }

.visible6 {
  -webkit-animation: fadeIn 2.6s ease-in;
          animation: fadeIn 2.6s ease-in;
  -moz-animation: fadeIn 2.6s ease-in; }

.visibleNT1 {
  -webkit-animation: fadeIn 1.5s ease-in;
          animation: fadeIn 1.5s ease-in;
  -moz-animation: fadeIn 1.5s ease-in; }

.visibleNT2 {
  -webkit-animation: fadeIn 1.8s ease-in;
          animation: fadeIn 1.8s ease-in;
  -moz-animation: fadeIn 1.8s ease-in; }

.visibleNT3 {
  -webkit-animation: fadeIn 2.1s ease-in;
          animation: fadeIn 2.1s ease-in;
  -moz-animation: fadeIn 2.1s ease-in; }

.visibleNT4 {
  -webkit-animation: fadeIn 2.4s ease-in;
          animation: fadeIn 2.4s ease-in;
  -moz-animation: fadeIn 2.4s ease-in; }

.visibleNT5 {
  -webkit-animation: fadeIn 2.7s ease-in;
          animation: fadeIn 2.7s ease-in;
  -moz-animation: fadeIn 2.7s ease-in; }

.visibleOut {
  -webkit-animation: fadeOut 2s ease-out;
          animation: fadeOut 2s ease-out;
  -moz-animation: fadeOut 2s ease-out; }

.visible7, .visibleNT6 {
  -webkit-animation: fadeIn 3s ease-in;
          animation: fadeIn 3s ease-in;
  -moz-animation: fadeIn 3s ease-in; }

.visibleNT7 {
  -webkit-animation: fadeIn 3.2s ease-in;
          animation: fadeIn 3.2s ease-in;
  -moz-animation: fadeIn 3.2s ease-in; }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }
@-webkit-keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }
@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }
.p-relative {
  position: relative; }

.filter-btn {
  background-color: #f6f4ff;
  border: none;
  padding: 10px 24px;
  border-radius: 6px;
  font-size: 16px;
  color: #ffffff; }

.radio_btn .form-check-input[type=radio] {
  background-color: #e9f1f8;
  border-color: #e9f1f8; }
.radio_btn .form-check-input:checked {
  /* background-color: #525962; */
  border: 4px solid #e9f1f8; }
.radio_btn .form-check-input:checked[type=radio] {
  background-image: none !important;
  background: #8675fa; }
.radio_btn .form-check-input:focus {
  -webkit-box-shadow: none;
          box-shadow: none; }
.radio_btn .form-check-input[type=radio] + label {
  font-weight: 500; }
.radio_btn .form-check-input:checked[type=radio] + label {
  font-weight: bold; }

.content_graph {
  padding-left: 304px; }
  .content_graph .total-cr {
    position: absolute;
    top: 52.3%;
    left: 29px; }
    .content_graph .total-cr .line-vr {
      position: relative; }
      .content_graph .total-cr .line-vr::before {
        content: "";
        background: #8189fa;
        background: -webkit-gradient(linear, left top, right top, from(rgba(129, 137, 250, 0.0733543417)), to(#8189fa));
        background: linear-gradient(90deg, rgba(129, 137, 250, 0.0733543417) 0%, #8189fa 100%);
        height: 2px;
        width: 154px;
        position: absolute;
        right: -174px;
        top: 35px; }

.total-cr_2 {
  position: absolute;
  top: 51.4%;
  left: 29px; }
  .total-cr_2 .line-vr {
    position: relative; }
    .total-cr_2 .line-vr::before {
      content: "";
      background: #8189fa;
      background: -webkit-gradient(linear, left top, right top, from(rgba(129, 137, 250, 0.0733543417)), to(#8189fa));
      background: linear-gradient(90deg, rgba(129, 137, 250, 0.0733543417) 0%, #8189fa 100%);
      height: 2px;
      width: 154px;
      position: absolute;
      right: -174px;
      top: 35px; }

.popupNode {
  position: absolute;
  padding: 10px 0 10px 10px;
  background-color: #fff;
  border-radius: 2px;
  -webkit-box-shadow: 0 10px 20px 2px rgba(0, 0, 0, 0.1);
          box-shadow: 0 10px 20px 2px rgba(0, 0, 0, 0.1);
  width: 75%;
  left: 0;
  top: 0;
  margin: 15px; }
  .popupNode ul {
    font-weight: 400;
    font-size: 12px;
    text-align: left;
    list-style: none;
    position: relative;
    margin: 0;
    padding-left: 0; }
    .popupNode ul.main:before {
      content: '';
      position: absolute;
      width: 1px;
      left: 10px;
      top: 18px;
      bottom: 10px;
      border-left: 1px dashed #ececed; }
    .popupNode ul.main > li:before {
      content: '';
      position: absolute;
      left: 8px;
      top: 14px;
      width: 5px;
      height: 5px;
      background: #3f5373 !important;
      border-radius: 100%; }
    .popupNode ul.main > li div {
      margin-bottom: 8px; }
    .popupNode ul.main li {
      padding: 8px 0px 0px 10px;
      position: relative; }
      .popupNode ul.main li:last-child {
        border-bottom: none;
        list-style: none;
        position: relative; }
      .popupNode ul.main li .cnt {
        padding-left: 10px;
        margin-bottom: 0 !important;
        border-bottom: 1px solid #ececed !important;
        padding-bottom: 10px; }
      .popupNode ul.main li .sub_list {
        padding-left: 0;
        margin-top: 0px;
        position: relative; }
        .popupNode ul.main li .sub_list:before {
          content: '';
          position: absolute;
          width: 1px;
          left: 10px;
          top: 18px;
          bottom: 10px;
          border-left: 1px dashed #ececed; }
        .popupNode ul.main li .sub_list:after {
          content: '';
          position: absolute;
          width: 1px;
          left: 0px;
          top: 13px;
          bottom: 0px;
          border-left: 1px solid #fff; }
        .popupNode ul.main li .sub_list li:before {
          content: '';
          position: absolute;
          left: 8px;
          top: 13px;
          width: 5px;
          height: 5px;
          border-radius: 100%; }
        .popupNode ul.main li .sub_list li:first-child:after {
          content: '';
          position: absolute;
          left: 0px;
          top: 10px;
          width: 7px;
          height: 5px;
          border-bottom: 1px dashed #ececed;
          background: transparent !important;
          border-left: 1px dashed #ececed;
          border-radius: 0 0 0 5px; }
    .popupNode ul .blue:before {
      background: #5500D5 !important; }
    .popupNode ul .light-blue:before {
      background: #CFAEFD; }
    .popupNode ul .text_blue {
      color: #5500D5; }
    .popupNode ul li.last-node .cnt {
      border-bottom: none !important; }

